import { graphql, type Environment } from 'react-relay';
import commitMutation from 'relay-commit-mutation-promise';

import {
    type FindingSharedModifyPortfolioItemsMutation,
    type FindingSharedModifyPortfolioItemsMutation$data,
    type PortfolioItemsAction,
    type PortfolioType,
} from './__generated__/FindingSharedModifyPortfolioItemsMutation.graphql';
import { type RecordSourceSelectorProxy } from 'relay-runtime';

const mutation = graphql`
    mutation FindingSharedModifyPortfolioItemsMutation($input: ModifyPortfolioItemsInput!) {
        modifyPortfolioItems(input: $input) {
            removedItemIds
        }
    }
`;

type Props = {
    action: PortfolioItemsAction;
    id?: string;
    itemId: string;
    userId: string;
    onCompleted?: () => void;
    onError?: () => void;
    portfolioType: PortfolioType;
};

export const commit = async (
    environment: Environment,
    { action, id, itemId, userId, onCompleted = () => {}, onError = () => {}, portfolioType }: Props
): Promise<void> => {
    const variables = {
        input: { action, itemsIds: [itemId], portfolioType, userId },
        userId,
        itemId,
    };

    const isFavorite = action === 'REMOVE' ? false : true;
    const updater = id
        ? (
              store: RecordSourceSelectorProxy<FindingSharedModifyPortfolioItemsMutation$data>
          ): void => {
              store.get(id)?.setValue(isFavorite, 'isFavorite', { userId });
          }
        : () => ({});

    try {
        await commitMutation<FindingSharedModifyPortfolioItemsMutation>(environment, {
            mutation,
            variables,
            optimisticUpdater: updater,
            updater,
        });
        onCompleted();
    } catch {
        onError();
    }
};

export const SharedModifyPortfolioItems = {
    commit,
};
