import { type FC, createContext, useContext, type ReactNode } from 'react';

import { type SocketListeners } from './types';

type SocketContextType = {
    socketListeners: SocketListeners | null;
    children?: ReactNode;
};

export const SocketContext = createContext<SocketContextType>({
    socketListeners: null,
});

export const SocketContextProvider: FC<SocketContextType> = ({ socketListeners, children }) => (
    <SocketContext.Provider value={{ socketListeners }}>{children}</SocketContext.Provider>
);

export function useSocketContext(): SocketContextType {
    return useContext(SocketContext);
}
